import {
  UserHome,
  UserContact,
  UserAbout,
  UserResources,
  UserBusiness,
  UserLoanApply,
  UserFaq,
  UserTerms,
  UserPrivacy,
  UserSupport,
  UserProfile,
  UserLines,
  UserTermLoan,
} from "../../pages/User";
import userRouteMap from "./userRouteMap";

export default function route() {
  return [
    {
      path: userRouteMap.USERHOME.path,
      name: "Home",
      element: <UserHome />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERCONTACT.path,
      name: "User Contact",
      element: <UserContact />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERABOUT.path,
      name: "User About",
      element: <UserAbout />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERRESOURCES.path,
      name: "User Resources",
      element: <UserResources />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERBUSINESS.path,
      name: "User Business",
      element: <UserBusiness />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERLOAN.path,
      name: "User Loan Apply",
      element: <UserLoanApply />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERFAQ.path,
      name: "User Faq",
      element: <UserFaq />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERTERMS.path,
      name: "User Terms",
      element: <UserTerms />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERPRIVACY.path,
      name: "User Privacy Policy",
      element: <UserPrivacy />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERSUPPORT.path,
      name: "User Support",
      element: <UserSupport />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERPROFILE.path,
      name: "User Profile",
      element: <UserProfile />,
      private: true,
      common: false,
    },
    {
      path: userRouteMap.USERLINES.path,
      name: "User Lines",
      element: <UserLines />,
      private: false,
      common: true,
    },
    {
      path: userRouteMap.USERTERMSLOAN.path,
      name: "User Term Loan",
      element: <UserTermLoan />,
      private: false,
      common: true,
    },
  ];
}
